"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractGroup = void 0;
const u_1 = require("../../u");
class ContractGroup {
    static fromJson(json) {
        return new ContractGroup({
            pubKey: json.pubkey,
            signature: u_1.HexString.fromBase64(json.signature).toBigEndian(),
        });
    }
    constructor(obj) {
        const { pubKey = "", signature = "" } = obj;
        this.pubKey = pubKey;
        this.signature = signature;
    }
    toJson() {
        return {
            pubkey: this.pubKey,
            signature: u_1.HexString.fromHex(this.signature).toBase64(),
        };
    }
    export() {
        return {
            pubKey: this.pubKey,
            signature: this.signature,
        };
    }
}
exports.ContractGroup = ContractGroup;
