"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TX_VERSION = exports.NEP2_FLAG = exports.NEP2_HEADER = exports.DEFAULT_ACCOUNT_CONTRACT = exports.DEFAULT_WALLET = exports.DEFAULT_SCRYPT = exports.DEFAULT_REQ = exports.ASSET_ID = exports.NATIVE_CONTRACT_HASH = exports.MAGIC_NUMBER = exports.DEFAULT_ADDRESS_VERSION = exports.ADDR_VERSION = void 0;
/**
 * @deprecated If you are looking for the default MainNet address version for N3, please use DEFAULT_ADDRESS_VERSION.
 */
exports.ADDR_VERSION = "35";
exports.DEFAULT_ADDRESS_VERSION = 0x35;
var MAGIC_NUMBER;
(function (MAGIC_NUMBER) {
    MAGIC_NUMBER[MAGIC_NUMBER["MainNet"] = 860833102] = "MainNet";
    MAGIC_NUMBER[MAGIC_NUMBER["TestNet"] = 894710606] = "TestNet";
    MAGIC_NUMBER[MAGIC_NUMBER["SoloNet"] = 1234567890] = "SoloNet";
})(MAGIC_NUMBER = exports.MAGIC_NUMBER || (exports.MAGIC_NUMBER = {}));
var NATIVE_CONTRACT_HASH;
(function (NATIVE_CONTRACT_HASH) {
    NATIVE_CONTRACT_HASH["NeoToken"] = "ef4073a0f2b305a38ec4050e4d3d28bc40ea63f5";
    NATIVE_CONTRACT_HASH["GasToken"] = "d2a4cff31913016155e38e474a2c06d08be276cf";
    NATIVE_CONTRACT_HASH["PolicyContract"] = "cc5e4edd9f5f8dba8bb65734541df7a1c081c67b";
    NATIVE_CONTRACT_HASH["ManagementContract"] = "fffdc93764dbaddd97c48f252a53ea4643faa3fd";
    NATIVE_CONTRACT_HASH["OracleContract"] = "fe924b7cfe89ddd271abaf7210a80a7e11178758";
    NATIVE_CONTRACT_HASH["LedgerContract"] = "da65b600f7124ce6c79950c1772a36403104f2be";
    NATIVE_CONTRACT_HASH["RoleManagement"] = "49cf4e5378ffcd4dec034fd98a174c5491e395e2";
    NATIVE_CONTRACT_HASH["StdLib"] = "acce6fd80d44e1796aa0c2c625e9e4e0ce39efc0";
    NATIVE_CONTRACT_HASH["CryptoLib"] = "726cb6e0cd8628a1350a611384688911ab75f51b";
})(NATIVE_CONTRACT_HASH = exports.NATIVE_CONTRACT_HASH || (exports.NATIVE_CONTRACT_HASH = {}));
/**
 * @deprecated Please use NATIVE_CONTRACT_HASH
 */
exports.ASSET_ID = {
    NEO: "de5f57d430d3dece511cf975a8d37848cb9e0525",
    GAS: "668e0c1f9d7b70a99dd9e06eadd4c784d641afbc",
};
exports.DEFAULT_REQ = {
    jsonrpc: "2.0",
    method: "getblockcount",
    params: [],
    id: 1234,
};
exports.DEFAULT_SCRYPT = {
    n: 16384,
    r: 8,
    p: 8,
    size: 64,
};
exports.DEFAULT_WALLET = {
    name: "myWallet",
    version: "1.0",
    scrypt: exports.DEFAULT_SCRYPT,
    extra: null,
};
exports.DEFAULT_ACCOUNT_CONTRACT = {
    script: "",
    parameters: [
        {
            name: "signature",
            type: "Signature",
        },
    ],
    deployed: false,
};
// specified by nep2, same as bip38
exports.NEP2_HEADER = "0142";
exports.NEP2_FLAG = "e0";
// transaction related
exports.TX_VERSION = 0;
