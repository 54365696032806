"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractAbi = void 0;
const ContractMethodDefinition_1 = require("./ContractMethodDefinition");
const ContractEventDefiniton_1 = require("./ContractEventDefiniton");
class ContractAbi {
    static fromJson(json) {
        return new ContractAbi({
            methods: json.methods.map((m) => ContractMethodDefinition_1.ContractMethodDefinition.fromJson(m)),
            events: json.events.map((e) => ContractEventDefiniton_1.ContractEventDefiniton.fromJson(e)),
        });
    }
    constructor(obj) {
        const { methods = [], events = [] } = obj;
        this.methods = methods.map((method) => new ContractMethodDefinition_1.ContractMethodDefinition(method));
        this.events = events.map((event) => new ContractEventDefiniton_1.ContractEventDefiniton(event));
    }
    toJson() {
        return {
            methods: this.methods.map((m) => m.toJson()),
            events: this.events.map((e) => e.toJson()),
        };
    }
    export() {
        return {
            methods: this.methods.map((method) => method.export()),
            events: this.events.map((event) => event.export()),
        };
    }
}
exports.ContractAbi = ContractAbi;
