"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OracleResponseAttribute = exports.HighPriorityAttribute = exports.TransactionAttribute = exports.OracleResponseCode = exports.TransactionAttributeType = void 0;
const u_1 = require("../../u");
const internal_1 = require("../../internal");
var TransactionAttributeType;
(function (TransactionAttributeType) {
    TransactionAttributeType[TransactionAttributeType["HighPriority"] = 1] = "HighPriority";
    TransactionAttributeType[TransactionAttributeType["OracleResponse"] = 17] = "OracleResponse";
})(TransactionAttributeType = exports.TransactionAttributeType || (exports.TransactionAttributeType = {}));
var OracleResponseCode;
(function (OracleResponseCode) {
    // Indicates that the request has been successfully completed.
    OracleResponseCode[OracleResponseCode["Success"] = 0] = "Success";
    // Indicates that the protocol of the request is not supported.
    OracleResponseCode[OracleResponseCode["ProtocolNotSupported"] = 16] = "ProtocolNotSupported";
    // Indicates that the oracle nodes cannot reach a consensus on the result of the request.
    OracleResponseCode[OracleResponseCode["ConsensusUnreachable"] = 18] = "ConsensusUnreachable";
    // Indicates that the requested Uri does not exist.
    OracleResponseCode[OracleResponseCode["NotFound"] = 20] = "NotFound";
    // Indicates that the request was not completed within the specified time.
    OracleResponseCode[OracleResponseCode["Timeout"] = 22] = "Timeout";
    // Indicates that there is no permission to request the resource.
    OracleResponseCode[OracleResponseCode["Forbidden"] = 24] = "Forbidden";
    // Indicates that the data for the response is too large.
    OracleResponseCode[OracleResponseCode["ResponseTooLarge"] = 26] = "ResponseTooLarge";
    // Indicates that the request failed due to insufficient balance.
    OracleResponseCode[OracleResponseCode["InsufficientFunds"] = 28] = "InsufficientFunds";
    // Indicates that the content-type of the request is not supported.
    OracleResponseCode[OracleResponseCode["ContentTypeNotSupported"] = 31] = "ContentTypeNotSupported";
    // Indicates that the request failed due to other errors.
    OracleResponseCode[OracleResponseCode["Error"] = 255] = "Error";
})(OracleResponseCode = exports.OracleResponseCode || (exports.OracleResponseCode = {}));
class TransactionAttribute {
    get size() {
        return 1;
    }
    static fromJson(input) {
        const attrType = (0, internal_1.parseEnum)(input.type, TransactionAttributeType);
        const implementingClass = this.getImplementation(attrType);
        return implementingClass.fromJson(input);
    }
    static fromStream(ss) {
        return TransactionAttribute.deserialize(ss);
    }
    static deserialize(ss) {
        const rawType = parseInt(ss.peek(1), 16);
        const attrType = (0, internal_1.parseEnum)(rawType, TransactionAttributeType);
        const implementingClass = this.getImplementation(attrType);
        return implementingClass.deserialize(ss);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static getImplementation(type) {
        switch (type) {
            case TransactionAttributeType.HighPriority:
                return HighPriorityAttribute;
            case TransactionAttributeType.OracleResponse:
                return OracleResponseAttribute;
            default:
                throw new Error(`Unknown TransactionAttributeType: ${type}`);
        }
    }
    serialize() {
        return this.type.toString(16).padStart(2, "0");
    }
}
exports.TransactionAttribute = TransactionAttribute;
class HighPriorityAttribute extends TransactionAttribute {
    get type() {
        return HighPriorityAttribute._type;
    }
    static fromJson(_) {
        return new HighPriorityAttribute();
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        return new HighPriorityAttribute();
    }
    toJson() {
        return { type: "HighPriority" };
    }
    export() {
        return {
            type: this.type,
        };
    }
}
HighPriorityAttribute._type = TransactionAttributeType.HighPriority;
exports.HighPriorityAttribute = HighPriorityAttribute;
class OracleResponseAttribute extends TransactionAttribute {
    get type() {
        return OracleResponseAttribute._type;
    }
    get size() {
        return this.serialize().length / 2;
    }
    static fromJson(input) {
        const code = (0, internal_1.parseEnum)(input.code, OracleResponseCode);
        return new OracleResponseAttribute(input.id, code, input.result);
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const id = parseInt(ss.read(8), 16);
        const codeName = OracleResponseCode[parseInt(ss.read(1), 16)];
        const code = (0, internal_1.parseEnum)(codeName, OracleResponseCode);
        const resultSize = ss.readVarInt();
        if (resultSize > 0xffff) {
            throw new Error(`Results size exceeds maximum`);
        }
        const result = ss.read(resultSize);
        return new OracleResponseAttribute(id, code, result);
    }
    constructor(id, code, result) {
        super();
        this.id = id;
        this.code = code;
        this.result = result;
    }
    toJson() {
        return {
            type: "OracleResponse",
            id: this.id,
            code: OracleResponseCode[this.code],
            result: this.result,
        };
    }
    serialize() {
        const id = (0, u_1.reverseHex)(this.id.toString(16).padStart(16, "0"));
        const code = this.code.toString(16).padStart(2, "0");
        const result = (0, u_1.base642hex)(this.result.toString());
        const resultLen = (0, u_1.num2VarInt)(result.length / 2);
        return super.serialize() + id + code + resultLen + result;
    }
    export() {
        return {
            type: this.type,
            id: this.id,
            code: this.code,
            result: this.result,
        };
    }
}
OracleResponseAttribute._type = TransactionAttributeType.OracleResponse;
exports.OracleResponseAttribute = OracleResponseAttribute;
function readAndAssertType(ss, type) {
    const rawType = parseInt(ss.read(1), 16);
    const txType = (0, internal_1.parseEnum)(rawType, TransactionAttributeType);
    if (txType !== type) {
        throw new Error(`Wrong TransactionAttributeType. Wanted ${TransactionAttributeType[type]} but got ${txType}`);
    }
}
exports.default = TransactionAttribute;
