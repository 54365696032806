"use strict";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _NEF_checksum;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NEF = void 0;
const u_1 = require("../u");
const MethodToken_1 = require("./MethodToken");
class NEF {
    get checksum() {
        if (!__classPrivateFieldGet(this, _NEF_checksum, "f")) {
            __classPrivateFieldSet(this, _NEF_checksum, this.computeCheckSum(), "f");
        }
        return __classPrivateFieldGet(this, _NEF_checksum, "f");
    }
    constructor(obj) {
        _NEF_checksum.set(this, void 0);
        const { compiler = "", source = "", tokens = [], script = "" } = obj;
        this.compiler = compiler;
        this.source = source;
        this.tokens = tokens.map((token) => new MethodToken_1.MethodToken(token));
        this.script = script;
    }
    static fromJson(json) {
        if (json.magic !== this.MAGIC) {
            throw new Error("Incorrect magic");
        }
        const nef = new NEF({
            compiler: json.compiler,
            source: json.source,
            tokens: json.tokens.map((t) => MethodToken_1.MethodToken.fromJson(t)),
            script: json.script,
        });
        if (nef.checksum !== json.checksum) {
            throw new Error("Invalid checksum");
        }
        return nef;
    }
    static fromBuffer(data) {
        const reader = new u_1.StringStream((0, u_1.ab2hexstring)(data));
        const magic = u_1.HexString.fromHex(reader.read(4), true).toNumber();
        if (magic !== this.MAGIC)
            throw new Error("NEF deserialization failure - incorrect magic");
        const compilerHexArray = Buffer.from(reader.read(64), "hex");
        const idx = compilerHexArray.indexOf(0x0);
        const compiler = compilerHexArray.slice(0, idx).toString();
        const sourceSize = reader.readVarInt();
        if (sourceSize > 256)
            throw new Error("NEF deserialization failure - source field size exceeds maximum length of 256");
        const source = Buffer.from(reader.read(sourceSize), "hex").toString();
        if (reader.read(1) !== "00")
            throw new Error("NEF deserialization failure - reserved bytes must be 0");
        const tokenLength = reader.readVarInt();
        if (tokenLength > 128)
            throw new Error("NEF deserialization failure - token array exceeds maximum length of 128");
        const tokens = [];
        for (let i = 0; i < tokenLength; i++) {
            tokens.push(MethodToken_1.MethodToken.fromStream(reader));
        }
        if (reader.read(2) !== "0000")
            throw new Error("NEF deserialization failure - reserved bytes must be 0");
        const scriptLength = reader.readVarInt();
        if (scriptLength === 0)
            throw new Error("NEF deserialization failure - script length can't be 0");
        if (scriptLength > this.MAX_SCRIPT_LENGTH)
            throw new Error("NEF deserialization failure - max script length exceeded");
        const script = reader.read(scriptLength);
        const checksum = Buffer.from(reader.read(4), "hex").readUInt32LE();
        const nef = new NEF({
            compiler: compiler,
            source: source,
            tokens: tokens,
            script: script,
        });
        if (nef.checksum !== checksum) {
            throw new Error("NEF deserialization failure - invalid checksum");
        }
        return nef;
    }
    toJson() {
        return {
            magic: NEF.MAGIC,
            compiler: this.compiler,
            source: this.source,
            tokens: this.tokens.map((t) => t.toJson()),
            script: this.script,
            checksum: this.checksum,
        };
    }
    get size() {
        return this.serialize().length;
    }
    serializeWithoutChecksum() {
        let out = "";
        out += (0, u_1.num2hexstring)(NEF.MAGIC, 4, true);
        out += (0, u_1.str2hexstring)(this.compiler).padEnd(128, "0");
        out += (0, u_1.num2VarInt)(this.source.length);
        out += (0, u_1.str2hexstring)(this.source);
        out += "00"; // reserved
        out += (0, u_1.serializeArrayOf)(this.tokens);
        out += "0000"; // reserved
        out += (0, u_1.num2VarInt)(this.script.length / 2);
        out += this.script;
        return out;
    }
    serialize() {
        let out = this.serializeWithoutChecksum();
        out += (0, u_1.num2hexstring)(this.checksum, 4, true);
        return out;
    }
    export() {
        return {
            compiler: this.compiler,
            source: this.source,
            tokens: this.tokens.map((t) => t.export()),
            script: this.script,
        };
    }
    computeCheckSum() {
        const data = this.serializeWithoutChecksum();
        const hash = (0, u_1.hash256)(data);
        return Buffer.from(hash, "hex").readUInt32LE();
    }
}
_NEF_checksum = new WeakMap();
NEF.MAX_SCRIPT_LENGTH = 512 * 1024;
NEF.MAGIC = 0x3346454e;
exports.NEF = NEF;
exports.default = NEF;
