"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OpCodeAnnotations = void 0;
const OpCode_1 = require("./OpCode");
/** Annotation details for OpCode. Tracks neo-vm/OpCode.cs */
// eslint-disable-next-line @typescript-eslint/naming-convention
exports.OpCodeAnnotations = {
    [OpCode_1.OpCode.PUSHINT8]: { operandSize: 1 },
    [OpCode_1.OpCode.PUSHINT16]: { operandSize: 2 },
    [OpCode_1.OpCode.PUSHINT32]: { operandSize: 4 },
    [OpCode_1.OpCode.PUSHINT64]: { operandSize: 8 },
    [OpCode_1.OpCode.PUSHINT128]: { operandSize: 16 },
    [OpCode_1.OpCode.PUSHINT256]: { operandSize: 32 },
    [OpCode_1.OpCode.PUSHT]: {},
    [OpCode_1.OpCode.PUSHF]: {},
    [OpCode_1.OpCode.PUSHA]: { operandSize: 4 },
    [OpCode_1.OpCode.PUSHNULL]: {},
    [OpCode_1.OpCode.PUSHDATA1]: { operandSizePrefix: 1 },
    [OpCode_1.OpCode.PUSHDATA2]: { operandSizePrefix: 2 },
    [OpCode_1.OpCode.PUSHDATA4]: { operandSizePrefix: 4 },
    [OpCode_1.OpCode.PUSHM1]: {},
    [OpCode_1.OpCode.PUSH0]: {},
    [OpCode_1.OpCode.PUSH1]: {},
    [OpCode_1.OpCode.PUSH2]: {},
    [OpCode_1.OpCode.PUSH3]: {},
    [OpCode_1.OpCode.PUSH4]: {},
    [OpCode_1.OpCode.PUSH5]: {},
    [OpCode_1.OpCode.PUSH6]: {},
    [OpCode_1.OpCode.PUSH7]: {},
    [OpCode_1.OpCode.PUSH8]: {},
    [OpCode_1.OpCode.PUSH9]: {},
    [OpCode_1.OpCode.PUSH10]: {},
    [OpCode_1.OpCode.PUSH11]: {},
    [OpCode_1.OpCode.PUSH12]: {},
    [OpCode_1.OpCode.PUSH13]: {},
    [OpCode_1.OpCode.PUSH14]: {},
    [OpCode_1.OpCode.PUSH15]: {},
    [OpCode_1.OpCode.PUSH16]: {},
    [OpCode_1.OpCode.NOP]: {},
    [OpCode_1.OpCode.JMP]: { operandSize: 1 },
    [OpCode_1.OpCode.JMP_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPIF]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPIF_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPIFNOT]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPIFNOT_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPEQ]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPEQ_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPNE]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPNE_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPGT]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPGT_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPGE]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPGE_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPLT]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPLT_L]: { operandSize: 4 },
    [OpCode_1.OpCode.JMPLE]: { operandSize: 1 },
    [OpCode_1.OpCode.JMPLE_L]: { operandSize: 4 },
    [OpCode_1.OpCode.CALL]: { operandSize: 1 },
    [OpCode_1.OpCode.CALL_L]: { operandSize: 4 },
    [OpCode_1.OpCode.CALLA]: {},
    [OpCode_1.OpCode.CALLT]: { operandSize: 2 },
    [OpCode_1.OpCode.ABORT]: {},
    [OpCode_1.OpCode.ASSERT]: {},
    [OpCode_1.OpCode.THROW]: {},
    [OpCode_1.OpCode.TRY]: { operandSize: 2 },
    [OpCode_1.OpCode.TRY_L]: { operandSize: 8 },
    [OpCode_1.OpCode.ENDTRY]: { operandSize: 1 },
    [OpCode_1.OpCode.ENDTRY_L]: { operandSize: 4 },
    [OpCode_1.OpCode.ENDFINALLY]: {},
    [OpCode_1.OpCode.RET]: {},
    [OpCode_1.OpCode.SYSCALL]: { operandSize: 4 },
    [OpCode_1.OpCode.DEPTH]: {},
    [OpCode_1.OpCode.DROP]: {},
    [OpCode_1.OpCode.NIP]: {},
    [OpCode_1.OpCode.XDROP]: {},
    [OpCode_1.OpCode.CLEAR]: {},
    [OpCode_1.OpCode.DUP]: {},
    [OpCode_1.OpCode.OVER]: {},
    [OpCode_1.OpCode.PICK]: {},
    [OpCode_1.OpCode.TUCK]: {},
    [OpCode_1.OpCode.SWAP]: {},
    [OpCode_1.OpCode.ROT]: {},
    [OpCode_1.OpCode.ROLL]: {},
    [OpCode_1.OpCode.REVERSE3]: {},
    [OpCode_1.OpCode.REVERSE4]: {},
    [OpCode_1.OpCode.REVERSEN]: {},
    [OpCode_1.OpCode.INITSSLOT]: { operandSize: 1 },
    [OpCode_1.OpCode.INITSLOT]: { operandSize: 2 },
    [OpCode_1.OpCode.LDSFLD0]: {},
    [OpCode_1.OpCode.LDSFLD1]: {},
    [OpCode_1.OpCode.LDSFLD2]: {},
    [OpCode_1.OpCode.LDSFLD3]: {},
    [OpCode_1.OpCode.LDSFLD4]: {},
    [OpCode_1.OpCode.LDSFLD5]: {},
    [OpCode_1.OpCode.LDSFLD6]: {},
    [OpCode_1.OpCode.LDSFLD]: { operandSize: 1 },
    [OpCode_1.OpCode.STSFLD0]: {},
    [OpCode_1.OpCode.STSFLD1]: {},
    [OpCode_1.OpCode.STSFLD2]: {},
    [OpCode_1.OpCode.STSFLD3]: {},
    [OpCode_1.OpCode.STSFLD4]: {},
    [OpCode_1.OpCode.STSFLD5]: {},
    [OpCode_1.OpCode.STSFLD6]: {},
    [OpCode_1.OpCode.STSFLD]: { operandSize: 1 },
    [OpCode_1.OpCode.LDLOC0]: {},
    [OpCode_1.OpCode.LDLOC1]: {},
    [OpCode_1.OpCode.LDLOC2]: {},
    [OpCode_1.OpCode.LDLOC3]: {},
    [OpCode_1.OpCode.LDLOC4]: {},
    [OpCode_1.OpCode.LDLOC5]: {},
    [OpCode_1.OpCode.LDLOC6]: {},
    [OpCode_1.OpCode.LDLOC]: { operandSize: 1 },
    [OpCode_1.OpCode.STLOC0]: {},
    [OpCode_1.OpCode.STLOC1]: {},
    [OpCode_1.OpCode.STLOC2]: {},
    [OpCode_1.OpCode.STLOC3]: {},
    [OpCode_1.OpCode.STLOC4]: {},
    [OpCode_1.OpCode.STLOC5]: {},
    [OpCode_1.OpCode.STLOC6]: {},
    [OpCode_1.OpCode.STLOC]: { operandSize: 1 },
    [OpCode_1.OpCode.LDARG0]: {},
    [OpCode_1.OpCode.LDARG1]: {},
    [OpCode_1.OpCode.LDARG2]: {},
    [OpCode_1.OpCode.LDARG3]: {},
    [OpCode_1.OpCode.LDARG4]: {},
    [OpCode_1.OpCode.LDARG5]: {},
    [OpCode_1.OpCode.LDARG6]: {},
    [OpCode_1.OpCode.LDARG]: { operandSize: 1 },
    [OpCode_1.OpCode.STARG0]: {},
    [OpCode_1.OpCode.STARG1]: {},
    [OpCode_1.OpCode.STARG2]: {},
    [OpCode_1.OpCode.STARG3]: {},
    [OpCode_1.OpCode.STARG4]: {},
    [OpCode_1.OpCode.STARG5]: {},
    [OpCode_1.OpCode.STARG6]: {},
    [OpCode_1.OpCode.STARG]: { operandSize: 1 },
    [OpCode_1.OpCode.NEWBUFFER]: {},
    [OpCode_1.OpCode.MEMCPY]: {},
    [OpCode_1.OpCode.CAT]: {},
    [OpCode_1.OpCode.SUBSTR]: {},
    [OpCode_1.OpCode.LEFT]: {},
    [OpCode_1.OpCode.RIGHT]: {},
    [OpCode_1.OpCode.INVERT]: {},
    [OpCode_1.OpCode.AND]: {},
    [OpCode_1.OpCode.OR]: {},
    [OpCode_1.OpCode.XOR]: {},
    [OpCode_1.OpCode.EQUAL]: {},
    [OpCode_1.OpCode.NOTEQUAL]: {},
    [OpCode_1.OpCode.SIGN]: {},
    [OpCode_1.OpCode.ABS]: {},
    [OpCode_1.OpCode.NEGATE]: {},
    [OpCode_1.OpCode.INC]: {},
    [OpCode_1.OpCode.DEC]: {},
    [OpCode_1.OpCode.ADD]: {},
    [OpCode_1.OpCode.SUB]: {},
    [OpCode_1.OpCode.MUL]: {},
    [OpCode_1.OpCode.DIV]: {},
    [OpCode_1.OpCode.MOD]: {},
    [OpCode_1.OpCode.POW]: {},
    [OpCode_1.OpCode.SQRT]: {},
    [OpCode_1.OpCode.SHL]: {},
    [OpCode_1.OpCode.SHR]: {},
    [OpCode_1.OpCode.NOT]: {},
    [OpCode_1.OpCode.BOOLAND]: {},
    [OpCode_1.OpCode.BOOLOR]: {},
    [OpCode_1.OpCode.NZ]: {},
    [OpCode_1.OpCode.NUMEQUAL]: {},
    [OpCode_1.OpCode.NUMNOTEQUAL]: {},
    [OpCode_1.OpCode.LT]: {},
    [OpCode_1.OpCode.LE]: {},
    [OpCode_1.OpCode.GT]: {},
    [OpCode_1.OpCode.GE]: {},
    [OpCode_1.OpCode.MIN]: {},
    [OpCode_1.OpCode.MAX]: {},
    [OpCode_1.OpCode.WITHIN]: {},
    [OpCode_1.OpCode.PACKMAP]: {},
    [OpCode_1.OpCode.PACKSTRUCT]: {},
    [OpCode_1.OpCode.PACK]: {},
    [OpCode_1.OpCode.UNPACK]: {},
    [OpCode_1.OpCode.NEWARRAY0]: {},
    [OpCode_1.OpCode.NEWARRAY]: {},
    [OpCode_1.OpCode.NEWARRAY_T]: { operandSize: 1 },
    [OpCode_1.OpCode.NEWSTRUCT0]: {},
    [OpCode_1.OpCode.NEWSTRUCT]: {},
    [OpCode_1.OpCode.NEWMAP]: {},
    [OpCode_1.OpCode.SIZE]: {},
    [OpCode_1.OpCode.HASKEY]: {},
    [OpCode_1.OpCode.KEYS]: {},
    [OpCode_1.OpCode.VALUES]: {},
    [OpCode_1.OpCode.PICKITEM]: {},
    [OpCode_1.OpCode.APPEND]: {},
    [OpCode_1.OpCode.SETITEM]: {},
    [OpCode_1.OpCode.REVERSEITEMS]: {},
    [OpCode_1.OpCode.REMOVE]: {},
    [OpCode_1.OpCode.CLEARITEMS]: {},
    [OpCode_1.OpCode.POPITEM]: {},
    [OpCode_1.OpCode.ISNULL]: {},
    [OpCode_1.OpCode.ISTYPE]: { operandSize: 1 },
    [OpCode_1.OpCode.CONVERT]: { operandSize: 1 },
};
