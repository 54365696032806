"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reverseHex = exports.ensureHex = exports.remove0xPrefix = exports.isHex = void 0;
const hexRegex = /^([0-9A-Fa-f]{2})*$/;
/**
 * Checks if input is a hexstring. Empty string is considered a hexstring.
 */
function isHex(str) {
    try {
        return hexRegex.test(str);
    }
    catch (err) {
        return false;
    }
}
exports.isHex = isHex;
/**
 * Remove the 0x prefix.
 */
function remove0xPrefix(str) {
    if (str.startsWith("0x")) {
        str = str.substring(2);
    }
    return str;
}
exports.remove0xPrefix = remove0xPrefix;
/**
 * Throws an error if input is not hexstring.
 */
function ensureHex(str) {
    if (!isHex(str)) {
        throw new Error(`Expected a hexstring but got ${str}`);
    }
}
exports.ensureHex = ensureHex;
/**
 * Reverses a HEX string, treating 2 chars as a byte.
 *
 * @example
 * reverseHex('abcdef') = 'efcdab'
 */
function reverseHex(hex) {
    ensureHex(hex);
    let out = "";
    for (let i = hex.length - 2; i >= 0; i -= 2) {
        out += hex.substr(i, 2);
    }
    return out;
}
exports.reverseHex = reverseHex;
