"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Protocol = void 0;
function compareStrings(current, other) {
    if (current.length !== other.length) {
        return false;
    }
    for (let i = 0; i < current.length; i++) {
        if (current[i] !== other[i]) {
            return false;
        }
    }
    return true;
}
/**
 * Model of the protocol configuration file used by the C# implementation.
 */
class Protocol {
    constructor(config = {}) {
        this.magic = config.magic ?? config.Magic ?? 0;
        this.addressVersion = config.addressVersion ?? config.AddressVersion ?? 53;
        this.standbyValidators =
            config.standbyValidators ?? config.StandbyValidators ?? [];
        this.seedList = config.seedList ?? config.SeedList ?? [];
    }
    get [Symbol.toStringTag]() {
        return "Protocol";
    }
    export() {
        return {
            magic: this.magic,
            addressVersion: this.addressVersion,
            standbyValidators: this.standbyValidators,
            seedList: this.seedList,
        };
    }
    toConfiguration() {
        return {
            Magic: this.magic,
            AddressVersion: this.addressVersion,
            StandbyValidators: this.standbyValidators,
            SeedList: this.seedList,
        };
    }
    equals(other) {
        return (this.magic === (other.magic ?? other.Magic) &&
            this.addressVersion === (other.addressVersion ?? other.AddressVersion) &&
            compareStrings(this.seedList, other.seedList ?? other.SeedList ?? []) &&
            compareStrings(this.standbyValidators, other.standbyValidators ?? other.StandbyValidators ?? []));
    }
}
exports.Protocol = Protocol;
exports.default = Protocol;
