"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractPermission = void 0;
class ContractPermission {
    /**
     *  Parses a ContractPermissionDescriptor.
     * @param jsonDescriptor - descriptor found in JSON format.
     * @returns a sanitized string
     */
    static parseJsonDescriptor(jsonDescriptor) {
        switch (true) {
            case jsonDescriptor.length === 66: // public key of contract manifest group
            case jsonDescriptor.length === 40: // contract scripthash
            case jsonDescriptor === "*": // wildcard, means it accept any contract
                return jsonDescriptor;
            case jsonDescriptor.length === 42 && jsonDescriptor.indexOf("0x") === 0: // contract with "0x" prefix
                return jsonDescriptor.slice(2);
            default:
                throw new Error(`This is not a ContractPermissionDescriptor: ${jsonDescriptor}`);
        }
    }
    /**
     * Converts an internal ContractPermissionDescriptor to JSON format.
     * @param descriptor - internal ContractPermissionDescriptor string
     * @returns JSON formatted string
     */
    static toJsonDescriptor(descriptor) {
        if (descriptor.length === 40)
            return `0x${descriptor}`;
        return descriptor;
    }
    fromJson(json) {
        return new ContractPermission(json);
    }
    constructor(obj = {}) {
        const { contract = "*", methods = "*" } = obj;
        this.contract = ContractPermission.parseJsonDescriptor(contract);
        this.methods = methods;
    }
    get isHash() {
        return this.contract.length === 40;
    }
    get isGroup() {
        return this.contract.length === 66;
    }
    get isWildcard() {
        return this.contract === "*";
    }
    toJson() {
        return {
            contract: ContractPermission.toJsonDescriptor(this.contract),
            methods: this.methods,
        };
    }
    export() {
        return {
            contract: this.contract,
            methods: this.methods,
        };
    }
}
exports.ContractPermission = ContractPermission;
