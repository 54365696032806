"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractMethodDefinition = void 0;
const ContractParam_1 = require("../ContractParam");
const internal_1 = require("../../internal");
class ContractMethodDefinition {
    static fromJson(json) {
        return new ContractMethodDefinition({
            name: json.name,
            offset: json.offset,
            parameters: json.parameters.map((p) => ({
                name: p.name,
                type: (0, internal_1.parseEnum)(p.type, ContractParam_1.ContractParamType),
            })),
            returnType: (0, internal_1.parseEnum)(json.returntype, ContractParam_1.ContractParamType),
            safe: json.safe,
        });
    }
    constructor(obj) {
        const { name = "", offset = 0, parameters = [], returnType = ContractParam_1.ContractParamType.Any, safe = false, } = obj;
        this.name = name;
        this.offset = offset;
        this.parameters = [...parameters];
        this.returnType = returnType;
        this.safe = safe;
    }
    toJson() {
        return {
            name: this.name,
            offset: this.offset,
            parameters: this.parameters.map((p) => ({
                name: p.name,
                type: ContractParam_1.ContractParamType[p.type],
            })),
            returntype: ContractParam_1.ContractParamType[this.returnType],
            safe: this.safe,
        };
    }
    export() {
        return {
            name: this.name,
            offset: this.offset,
            parameters: [...this.parameters],
            returnType: this.returnType,
            safe: this.safe,
        };
    }
}
exports.ContractMethodDefinition = ContractMethodDefinition;
