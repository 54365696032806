"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NeoServerRpcClient = exports.NeoServerRpcMixin = void 0;
const Query_1 = require("../Query");
const RpcDispatcher_1 = require("./RpcDispatcher");
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
function NeoServerRpcMixin(base) {
    return class NeoServerRpcInterface extends base {
        //#region Blockchain
        /**
         * Query returning the Iterator value from session and Iterator id returned by invokefunction or invokescript.
         * @param sessionId - Cache id. It is session returned by invokefunction or invokescript.
         * @param iteratorId - Iterator data id. It is the id of stack returned by invokefunction or invokescript .
         * @param count - The number of values returned. It cannot exceed the value of the MaxIteratorResultItems field in config.json of the RpcServer plug-in.
         * The result is the first count of data traversed in the Iterator, and follow-up requests will continue traversing from count + 1 .
         */
        async traverseIterator(sessionId, iteratorId, count) {
            const response = await this.execute(Query_1.Query.traverseIterator(sessionId, iteratorId, count));
            return response;
        }
        /**
         * Get the latest block hash.
         */
        async getBestBlockHash() {
            const response = await this.execute(Query_1.Query.getBestBlockHash());
            return response;
        }
        async getBlock(indexOrHash, verbose) {
            return verbose
                ? await this.execute(Query_1.Query.getBlock(indexOrHash, 1))
                : await this.execute(Query_1.Query.getBlock(indexOrHash, 0));
        }
        /**
         * Gets the block hash at a given height.
         */
        async getBlockHash(index) {
            const response = await this.execute(Query_1.Query.getBlockHash(index));
            return response;
        }
        /**
         * Get the current block height.
         */
        async getBlockCount() {
            const response = await this.execute(Query_1.Query.getBlockCount());
            return response;
        }
        async getBlockHeader(indexOrHash, verbose) {
            return verbose
                ? await this.execute(Query_1.Query.getBlockHeader(indexOrHash, 1))
                : await this.execute(Query_1.Query.getBlockHeader(indexOrHash, 0));
        }
        /**
         * Get the list of public keys in the committee.
         */
        async getCommittee() {
            return await this.execute(Query_1.Query.getCommittee());
        }
        /**
         * Gets the state of the contract at the given scriptHash.
         */
        async getContractState(scriptHash) {
            const response = await this.execute(Query_1.Query.getContractState(scriptHash));
            return response;
        }
        async getNativeContracts() {
            const response = await this.execute(Query_1.Query.getNativeContracts());
            return response;
        }
        async getRawMemPool(shouldGetUnverified = 0) {
            return shouldGetUnverified
                ? await this.execute(Query_1.Query.getRawMemPool(1))
                : await this.execute(Query_1.Query.getRawMemPool(0));
        }
        async getRawTransaction(txid, verbose) {
            return verbose
                ? await this.execute(Query_1.Query.getRawTransaction(txid, 1))
                : await this.execute(Query_1.Query.getRawTransaction(txid, 0));
        }
        /**
         * Gets the corresponding value of a key in the storage of a contract address.
         */
        async getStorage(scriptHash, key) {
            const response = await this.execute(Query_1.Query.getStorage(scriptHash, key));
            return response;
        }
        /**
         * Gets the block index in which the transaction is found.
         * @param txid - hash of the specific transaction.
         */
        async getTransactionHeight(txid) {
            const response = await this.execute(Query_1.Query.getTransactionHeight(txid));
            return response;
        }
        //#endregion
        //#region Node
        /**
         * Gets the number of peers this node is connected to.
         */
        async getConnectionCount() {
            const response = await this.execute(Query_1.Query.getConnectionCount());
            return response;
        }
        /**
         * Gets the list of validators available for voting.
         */
        async getNextBlockValidators() {
            const response = await this.execute(Query_1.Query.getNextBlockValidators());
            return response;
        }
        /**
         * Gets a list of all peers that this node has discovered.
         */
        async getPeers() {
            const response = await this.execute(Query_1.Query.getPeers());
            return response;
        }
        /**
         * Gets the version of the NEO node along with various other metadata.
         */
        async getVersion() {
            const response = await this.execute(Query_1.Query.getVersion());
            return response;
        }
        /**
         * Sends a serialized transaction to the network.
         * @returns transaction id
         */
        async sendRawTransaction(transaction) {
            const response = await this.execute(Query_1.Query.sendRawTransaction(transaction));
            return response.hash;
        }
        /**
         * Submits a serialized block to the network.
         * @returns block hash if success
         */
        async submitBlock(block) {
            const response = await this.execute(Query_1.Query.submitBlock(block));
            return response.hash;
        }
        //#endregion
        //#region SmartContract
        /**
         * Get the amount of unclaimed GAS for a NEO address. This is returned as the raw value. To get the display value, divide this by 100000000.
         */
        async getUnclaimedGas(addr) {
            const response = await this.execute(Query_1.Query.getUnclaimedGas(addr));
            return response.unclaimed;
        }
        /**
         * Submits a verification script to run under a contract.
         * @param scriptHash - contract to test
         * @param args - arguments to pass
         * @param signers - Signers to be included in transaction
         */
        async invokeContractVerify(scriptHash, args, signers = []) {
            return await this.execute(Query_1.Query.invokeContractVerify(scriptHash, args, signers));
        }
        /**
         * Submits a contract method call with parameters for the node to run. This method is a local invoke, results are not reflected on the blockchain.
         */
        async invokeFunction(scriptHash, operation, params = [], signers = []) {
            return await this.execute(Query_1.Query.invokeFunction(scriptHash, operation, params, signers));
        }
        /**
         * Submits a script for the node to run. This method is a local invoke, results are not reflected on the blockchain.
         *
         * @param script - base64-encoded hexstring.
         * @param signers - signatures accompanying this execution.
         */
        async invokeScript(script, signers = []) {
            return await this.execute(Query_1.Query.invokeScript(script, signers));
        }
        //#endregion
        //#region Wallet
        /**
         * Returns the gas fee for a transaction.
         * Transaction needs to have:
         * - All the intended signers
         * - Empty witnesses corresponding to the signers
         * - Non-zero script
         *
         * @param tx - transaction to calculate fee for
         * @returns GAS fee as an stringified integer
         */
        async calculateNetworkFee(tx) {
            const response = await this.execute(Query_1.Query.calculateNetworkFee(tx));
            return response.networkfee;
        }
        //#endregion
        //#region Utilities
        /**
         * Returns a list of plugins loaded by the node.
         */
        async listPlugins() {
            const response = await this.execute(Query_1.Query.listPlugins());
            return response;
        }
        /**
         * Checks if the provided address is a valid NEO address.
         */
        async validateAddress(addr) {
            const response = await this.execute(Query_1.Query.validateAddress(addr));
            return response.isvalid;
        }
    };
}
exports.NeoServerRpcMixin = NeoServerRpcMixin;
/**
 * RPC Client model to query a NEO node. Contains built-in methods to query using RPC calls.
 */
class NeoServerRpcClient extends NeoServerRpcMixin(RpcDispatcher_1.RpcDispatcher) {
    get [Symbol.toStringTag]() {
        return `NeoServerRpcClient(${this.url})`;
    }
}
exports.NeoServerRpcClient = NeoServerRpcClient;
