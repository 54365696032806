"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalledByGroupWitnessCondition = exports.CalledByContractWitnessCondition = exports.CalledByEntryWitnessCondition = exports.GroupWitnessCondition = exports.ScriptHashWitnessCondition = exports.OrWitnessCondition = exports.NotWitnessCondition = exports.AndWitnessCondition = exports.BooleanWitnessCondition = exports.WitnessCondition = exports.WitnessConditionType = void 0;
const internal_1 = require("../../internal");
const u_1 = require("../../u");
var WitnessConditionType;
(function (WitnessConditionType) {
    WitnessConditionType[WitnessConditionType["Boolean"] = 0] = "Boolean";
    WitnessConditionType[WitnessConditionType["Not"] = 1] = "Not";
    WitnessConditionType[WitnessConditionType["And"] = 2] = "And";
    WitnessConditionType[WitnessConditionType["Or"] = 3] = "Or";
    WitnessConditionType[WitnessConditionType["ScriptHash"] = 24] = "ScriptHash";
    WitnessConditionType[WitnessConditionType["Group"] = 25] = "Group";
    WitnessConditionType[WitnessConditionType["CalledByEntry"] = 32] = "CalledByEntry";
    WitnessConditionType[WitnessConditionType["CalledByContract"] = 40] = "CalledByContract";
    WitnessConditionType[WitnessConditionType["CalledByGroup"] = 41] = "CalledByGroup";
})(WitnessConditionType = exports.WitnessConditionType || (exports.WitnessConditionType = {}));
class WitnessCondition {
    get size() {
        return 1;
    }
    static fromJson(input) {
        const witnessType = (0, internal_1.parseEnum)(input.type, WitnessConditionType);
        const implementingClass = this.getImplementation(witnessType);
        return implementingClass.fromJson(input);
    }
    static deserialize(ss) {
        const rawType = parseInt(ss.peek(1), 16);
        const witnessType = (0, internal_1.parseEnum)(rawType, WitnessConditionType);
        const implementingClass = WitnessCondition.getImplementation(witnessType);
        return implementingClass.deserialize(ss);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    static getImplementation(type) {
        switch (type) {
            case WitnessConditionType.And:
                return AndWitnessCondition;
            case WitnessConditionType.Not:
                return NotWitnessCondition;
            case WitnessConditionType.Boolean:
                return BooleanWitnessCondition;
            case WitnessConditionType.Or:
                return OrWitnessCondition;
            case WitnessConditionType.ScriptHash:
                return ScriptHashWitnessCondition;
            case WitnessConditionType.Group:
                return GroupWitnessCondition;
            case WitnessConditionType.CalledByEntry:
                return CalledByEntryWitnessCondition;
            case WitnessConditionType.CalledByContract:
                return CalledByContractWitnessCondition;
            case WitnessConditionType.CalledByGroup:
                return CalledByGroupWitnessCondition;
            default:
                throw new Error(`Unknown WitnessConditionType: ${type}`);
        }
    }
    serialize() {
        return this.type.toString(16).padStart(2, "0");
    }
}
exports.WitnessCondition = WitnessCondition;
class BooleanWitnessCondition extends WitnessCondition {
    get type() {
        return BooleanWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const expression = ss.read(1) === "01";
        return new BooleanWitnessCondition(expression);
    }
    static fromJson(input) {
        return new BooleanWitnessCondition(input.expression);
    }
    get size() {
        return super.size + 1;
    }
    constructor(expression) {
        super();
        this.expression = expression;
    }
    serialize() {
        return super.serialize() + (this.expression ? "01" : "00");
    }
    toJson() {
        return {
            type: "Boolean",
            expression: this.expression,
        };
    }
}
BooleanWitnessCondition._type = WitnessConditionType.Boolean;
exports.BooleanWitnessCondition = BooleanWitnessCondition;
class AndWitnessCondition extends WitnessCondition {
    get type() {
        return AndWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const expressions = (0, u_1.deserializeArrayOf)(WitnessCondition.deserialize, ss);
        return new AndWitnessCondition(expressions);
    }
    static fromJson(input) {
        return new AndWitnessCondition(input.expressions.map((e) => WitnessCondition.fromJson(e)));
    }
    get size() {
        return super.size + (0, u_1.getSerializedSize)(this.expressions);
    }
    constructor(expressions) {
        super();
        this.expressions = expressions;
    }
    serialize() {
        return super.serialize() + (0, u_1.serializeArrayOf)(this.expressions);
    }
    toJson() {
        return {
            type: "And",
            expressions: this.expressions.map((e) => e.toJson()),
        };
    }
}
AndWitnessCondition._type = WitnessConditionType.And;
exports.AndWitnessCondition = AndWitnessCondition;
class NotWitnessCondition extends WitnessCondition {
    get type() {
        return NotWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const expression = WitnessCondition.deserialize(ss);
        return new NotWitnessCondition(expression);
    }
    static fromJson(input) {
        return new NotWitnessCondition(WitnessCondition.fromJson(input.expression));
    }
    get size() {
        return super.size + this.expression.size;
    }
    constructor(expression) {
        super();
        this.expression = expression;
    }
    serialize() {
        return super.serialize() + this.expression.serialize();
    }
    toJson() {
        return { type: "Not", expression: this.expression.toJson() };
    }
}
NotWitnessCondition._type = WitnessConditionType.Not;
exports.NotWitnessCondition = NotWitnessCondition;
class OrWitnessCondition extends WitnessCondition {
    get type() {
        return OrWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const expressions = (0, u_1.deserializeArrayOf)(WitnessCondition.deserialize, ss);
        return new OrWitnessCondition(expressions);
    }
    static fromJson(input) {
        return new OrWitnessCondition(input.expressions.map((e) => WitnessCondition.fromJson(e)));
    }
    get size() {
        return super.size + (0, u_1.getSerializedSize)(this.expressions);
    }
    constructor(expressions) {
        super();
        this.expressions = expressions;
    }
    serialize() {
        return super.serialize() + (0, u_1.serializeArrayOf)(this.expressions);
    }
    toJson() {
        return {
            type: "Or",
            expressions: this.expressions.map((e) => e.toJson()),
        };
    }
}
OrWitnessCondition._type = WitnessConditionType.Or;
exports.OrWitnessCondition = OrWitnessCondition;
class ScriptHashWitnessCondition extends WitnessCondition {
    get type() {
        return ScriptHashWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const hash = u_1.HexString.fromHex(ss.read(20), true);
        return new ScriptHashWitnessCondition(hash);
    }
    static fromJson(input) {
        return new ScriptHashWitnessCondition(input.hash);
    }
    get size() {
        return super.size + 20;
    }
    constructor(inputHash) {
        super();
        this.hash = u_1.HexString.fromHex(inputHash);
        if (this.hash.length !== 40) {
            throw new Error(`ScriptHashWitnessCondition only accepts a scripthash of 20 bytes but got ${this.hash.toString()}`);
        }
    }
    serialize() {
        return super.serialize() + this.hash.toLittleEndian();
    }
    toJson() {
        return {
            type: "ScriptHash",
            hash: this.hash.toString(),
        };
    }
}
ScriptHashWitnessCondition._type = WitnessConditionType.ScriptHash;
exports.ScriptHashWitnessCondition = ScriptHashWitnessCondition;
class GroupWitnessCondition extends WitnessCondition {
    get type() {
        return GroupWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const group = ss.read(33);
        return new GroupWitnessCondition(group);
    }
    static fromJson(input) {
        return new GroupWitnessCondition(input.group);
    }
    get size() {
        return super.size + 33;
    }
    constructor(inputGroup) {
        super();
        this.group = u_1.HexString.fromHex(inputGroup);
        if (this.group.length !== 66) {
            throw new Error(`GroupWitnessCondition only accepts a encoded public key of 33 bytes but got ${this.group.toString()}`);
        }
    }
    serialize() {
        return super.serialize() + this.group.toString();
    }
    toJson() {
        return {
            type: "Group",
            group: this.group.toString(),
        };
    }
}
GroupWitnessCondition._type = WitnessConditionType.Group;
exports.GroupWitnessCondition = GroupWitnessCondition;
class CalledByEntryWitnessCondition extends WitnessCondition {
    get type() {
        return CalledByEntryWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        return new CalledByEntryWitnessCondition();
    }
    static fromJson(_input) {
        return new CalledByEntryWitnessCondition();
    }
    get size() {
        return super.size;
    }
    constructor() {
        super();
    }
    serialize() {
        return super.serialize();
    }
    toJson() {
        return { type: "CalledByEntry" };
    }
}
CalledByEntryWitnessCondition._type = WitnessConditionType.CalledByEntry;
exports.CalledByEntryWitnessCondition = CalledByEntryWitnessCondition;
class CalledByContractWitnessCondition extends WitnessCondition {
    get type() {
        return CalledByContractWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const hash = u_1.HexString.fromHex(ss.read(20), true);
        return new CalledByContractWitnessCondition(hash);
    }
    static fromJson(input) {
        return new CalledByContractWitnessCondition(input.hash);
    }
    get size() {
        return super.size + 20;
    }
    constructor(inputHash) {
        super();
        this.hash = u_1.HexString.fromHex(inputHash);
        if (this.hash.length !== 40) {
            throw new Error(`CalledByContractWitnessCondition only accepts a scripthash of 20 bytes but got ${this.hash.toString()}`);
        }
    }
    serialize() {
        return super.serialize() + this.hash.toLittleEndian();
    }
    toJson() {
        return {
            type: "CalledByContract",
            hash: "0x" + this.hash.toString(),
        };
    }
}
CalledByContractWitnessCondition._type = WitnessConditionType.CalledByContract;
exports.CalledByContractWitnessCondition = CalledByContractWitnessCondition;
class CalledByGroupWitnessCondition extends WitnessCondition {
    get type() {
        return CalledByGroupWitnessCondition._type;
    }
    static deserialize(ss) {
        readAndAssertType(ss, this._type);
        const group = ss.read(33);
        return new CalledByGroupWitnessCondition(group);
    }
    static fromJson(input) {
        return new CalledByGroupWitnessCondition(input.group);
    }
    get size() {
        return super.size + 33;
    }
    constructor(inputGroup) {
        super();
        this.group = u_1.HexString.fromHex(inputGroup);
        if (this.group.length !== 66) {
            throw new Error(`CalledByGroupWitnessCondition only accepts a encoded public key of 33 bytes but got ${this.group.toString()}`);
        }
    }
    serialize() {
        return super.serialize() + this.group.toString();
    }
    toJson() {
        return {
            type: "CalledByGroup",
            group: this.group.toString(),
        };
    }
}
CalledByGroupWitnessCondition._type = WitnessConditionType.CalledByGroup;
exports.CalledByGroupWitnessCondition = CalledByGroupWitnessCondition;
function readAndAssertType(ss, type) {
    const rawType = parseInt(ss.read(1), 16);
    const witnessType = (0, internal_1.parseEnum)(rawType, WitnessConditionType);
    if (witnessType !== type) {
        throw new Error(`Wrong WitnessConditionType. Wanted ${WitnessConditionType[type]} but got ${witnessType}`);
    }
}
