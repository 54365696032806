"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Signer = void 0;
const WitnessScope_1 = require("./WitnessScope");
const u_1 = require("../../u");
const WitnessRule_1 = require("./WitnessRule");
class Signer {
    static fromJson(input) {
        return new Signer({
            account: input.account,
            scopes: (0, WitnessScope_1.parse)(input.scopes),
            allowedContracts: input.allowedcontracts ?? [],
            allowedGroups: input.allowedgroups ?? [],
            rules: input.rules ?? [],
        });
    }
    constructor(signer = {}) {
        const { account = "", scopes = WitnessScope_1.WitnessScope.None, allowedContracts = [], allowedGroups = [], rules = [], } = signer;
        this.account = u_1.HexString.fromHex(account);
        this.scopes =
            (typeof scopes === "string" ? (0, WitnessScope_1.parse)(scopes) : scopes) & 0xff;
        this.allowedContracts = allowedContracts.map((i) => u_1.HexString.fromHex(i));
        this.allowedGroups = allowedGroups.map((i) => u_1.HexString.fromHex(i));
        this.rules = rules.map((i) => new WitnessRule_1.WitnessRule(i));
    }
    /**
     * Returns the number of bytes this object will take when serialized.
     */
    get size() {
        return this.serialize().length / 2;
    }
    addAllowedContracts(...contracts) {
        if (this.scopes & WitnessScope_1.WitnessScope.Global) {
            return;
        }
        this.scopes |= WitnessScope_1.WitnessScope.CustomContracts;
        contracts
            .map((i) => u_1.HexString.fromHex(i))
            .forEach((i) => this.allowedContracts.push(i));
    }
    addAllowedGroups(...groups) {
        if (this.scopes & WitnessScope_1.WitnessScope.Global) {
            return;
        }
        this.scopes |= WitnessScope_1.WitnessScope.CustomGroups;
        groups
            .map((i) => u_1.HexString.fromHex(i))
            .forEach((i) => this.allowedGroups.push(i));
    }
    addRules(...rules) {
        if (this.scopes & WitnessScope_1.WitnessScope.Global) {
            return;
        }
        this.scopes |= WitnessScope_1.WitnessScope.WitnessRules;
        for (const rule of rules) {
            this.rules.push(rule);
        }
    }
    static deserialize(ss) {
        const account = u_1.HexString.fromHex(ss.read(20), true);
        const scopes = parseInt(ss.read(), 16);
        const allowedContracts = scopes & WitnessScope_1.WitnessScope.CustomContracts
            ? (0, u_1.deserializeArrayOf)((s) => u_1.HexString.fromHex(s.read(20), true), ss)
            : [];
        const allowedGroups = scopes & WitnessScope_1.WitnessScope.CustomGroups
            ? (0, u_1.deserializeArrayOf)((s) => u_1.HexString.fromHex(s.read(33)), ss)
            : [];
        const rules = scopes & WitnessScope_1.WitnessScope.WitnessRules
            ? (0, u_1.deserializeArrayOf)(WitnessRule_1.WitnessRule.deserialize, ss)
            : [];
        return new Signer({
            account,
            scopes,
            allowedContracts,
            allowedGroups,
            rules,
        });
    }
    /**
     * Merges the other Signer into this signer.
     * Deduplicates contracts and groups.
     * Modifies the original Signer and returns it.
     */
    merge(other) {
        const otherSigner = other instanceof Signer ? other : new Signer(other);
        if (!this.account.equals(otherSigner.account)) {
            throw new Error("Cannot merge Signers of different accounts!");
        }
        this.scopes |= otherSigner.scopes;
        // Global scope
        if (this.scopes & WitnessScope_1.WitnessScope.Global) {
            this.scopes = WitnessScope_1.WitnessScope.Global;
            this.allowedContracts = [];
            this.allowedGroups = [];
            return this;
        }
        if (otherSigner.allowedContracts) {
            const deduplicatedContracts = otherSigner.allowedContracts.filter((i) => !this.allowedContracts.some((j) => j.equals(i)));
            this.allowedContracts = this.allowedContracts.concat(deduplicatedContracts);
        }
        if (otherSigner.allowedGroups) {
            const deduplicatedGroups = otherSigner.allowedGroups.filter((i) => !this.allowedGroups.some((j) => j.equals(i)));
            this.allowedGroups = this.allowedGroups.concat(deduplicatedGroups);
        }
        return this;
    }
    serialize() {
        let out = "";
        out += this.account.toLittleEndian();
        out += (0, u_1.num2hexstring)(this.scopes, 1);
        if (this.scopes & WitnessScope_1.WitnessScope.CustomContracts) {
            out += (0, u_1.serializeArrayOf)(this.allowedContracts.map((i) => i.toLittleEndian()));
        }
        if (this.scopes & WitnessScope_1.WitnessScope.CustomGroups) {
            out += (0, u_1.serializeArrayOf)(this.allowedGroups.map((i) => i.toBigEndian()));
        }
        if (this.scopes & WitnessScope_1.WitnessScope.WitnessRules) {
            out += (0, u_1.serializeArrayOf)(this.rules);
        }
        return out;
    }
    export() {
        const output = {
            account: this.account.toBigEndian(),
            scopes: this.scopes,
        };
        if (this.scopes & WitnessScope_1.WitnessScope.CustomContracts) {
            output.allowedContracts = [
                ...this.allowedContracts.map((i) => i.toBigEndian()),
            ];
        }
        if (this.scopes & WitnessScope_1.WitnessScope.CustomGroups) {
            output.allowedGroups = [
                ...this.allowedGroups.map((i) => i.toBigEndian()),
            ];
        }
        return output;
    }
    toJson() {
        const output = {
            account: "0x" + this.account.toBigEndian(),
            scopes: (0, WitnessScope_1.toString)(this.scopes),
        };
        if (this.scopes & WitnessScope_1.WitnessScope.CustomContracts) {
            output.allowedcontracts = [
                ...this.allowedContracts.map((i) => "0x" + i.toBigEndian()),
            ];
        }
        if (this.scopes & WitnessScope_1.WitnessScope.CustomGroups) {
            output.allowedgroups = [
                ...this.allowedGroups.map((i) => i.toBigEndian()),
            ];
        }
        if (this.scopes & WitnessScope_1.WitnessScope.WitnessRules) {
            output.rules = [...this.rules.map((i) => i.toJson())];
        }
        return output;
    }
}
exports.Signer = Signer;
exports.default = Signer;
