"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractManifest = void 0;
const ContractGroup_1 = require("./ContractGroup");
const ContractAbi_1 = require("./ContractAbi");
const ContractPermission_1 = require("./ContractPermission");
class ContractManifest {
    static fromJson(json) {
        if (Object.keys(json.features).length != 0) {
            throw new Error("Features is reserved for future use and must be empty");
        }
        return new ContractManifest({
            name: json.name,
            groups: json.groups.map((g) => ContractGroup_1.ContractGroup.fromJson(g)),
            features: {},
            abi: ContractAbi_1.ContractAbi.fromJson(json.abi),
            supportedStandards: json.supportedstandards,
            permissions: json.permissions,
            trusts: json.trusts,
            extra: json.extra,
        });
    }
    constructor(obj) {
        const { name = "", groups = [], features = {}, abi = {}, supportedStandards = [], permissions = [], trusts = "*", extra, } = obj;
        this.name = name;
        this.groups = groups.map((group) => new ContractGroup_1.ContractGroup(group));
        this.features = features;
        this.supportedStandards = supportedStandards;
        this.abi = new ContractAbi_1.ContractAbi(abi);
        this.permissions = permissions.map((permission) => new ContractPermission_1.ContractPermission(permission));
        this.trusts = Array.isArray(trusts)
            ? trusts.map((t) => ContractPermission_1.ContractPermission.parseJsonDescriptor(t))
            : trusts;
        this.extra = extra;
    }
    toJson() {
        return {
            name: this.name,
            groups: this.groups.map((g) => g.toJson()),
            features: this.features,
            supportedstandards: this.supportedStandards,
            abi: this.abi.toJson(),
            permissions: this.permissions.map((p) => p.toJson()),
            trusts: Array.isArray(this.trusts)
                ? this.trusts.map((t) => ContractPermission_1.ContractPermission.toJsonDescriptor(t))
                : this.trusts,
            extra: this.extra,
        };
    }
    export() {
        return {
            name: this.name,
            groups: this.groups.map((group) => group.export()),
            features: this.features,
            supportedStandards: this.supportedStandards,
            abi: this.abi.export(),
            permissions: this.permissions.map((permission) => permission.export()),
            trusts: this.trusts,
            extra: this.extra,
        };
    }
}
ContractManifest.MAX_LENGTH = 0xffff;
exports.ContractManifest = ContractManifest;
exports.default = ContractManifest;
